<template>
  <div>
    <div id="pageEpisode" class="mainContents">
      <div class="episodeProfile">
        <p class="profile" :class="e.user.profile_color">
          <router-link :to="{ name: 'user-show', params: { id: e.user.id } }">
            <BaseUserImg :user="e.user" />{{ e.user.full_name }}
          </router-link><span>のエピソード</span>
        </p>
      </div>

      <div class="episodeArea">
        <div class="inner">
          <p class="episode">
            <strong>{{ e.time_display }}</strong>エピソード
          </p>
          <h2>{{ e.title }}</h2>
          <LikeButtonP ref="likeButton" :object="e" object-type="EPISODE" />
          <div class="hash">
            <ul>
              <li v-for="(tag, index) in e.tags" :key="index">
                ＃{{ tag.name }}
              </li>
            </ul>
          </div>
          <div class="editorInner" v-html="e.description_stripped" />
          <p class="update">
            {{ e.date }}
          </p>
        </div>
        <!-- /.episodeArea -->
      </div>

      <div class="snsArea">
        <div class="inner">
          <LikeButtonP ref="likeButton" :object="e" object-type="EPISODE" />
          <SnsButtons :text="pageTitle" :url="pageUrl" />
        </div>
        <!-- /.snsArea -->
      </div>

      <div v-if="e.allow_comments" class="messageArea">
        <div class="inner">
          <template v-if="!loggedIn">
            コメントするにはログインしてください
          </template>
          <template v-if="loggedIn">
            <h2>
              この記事にコメントする
            </h2>
            <form>
              <div class="textareaFrame">
                <textarea v-model="comment" placeholder="コメント入力..." />
              </div>
              <p class="button">
                <button
                  type="submit"
                  :disabled="formInvalid"
                  class="button"
                  @click="saveComment($event)"
                >
                  送信
                </button>
              </p>
            </form>
          </template>
          <ul class="messageList">
            <BaseCommentCard
              v-for="c in episodeComment.comments"
              :key="c.id"
              :comment="c.node"
              :type="'episode'"
              :owner="e.user"
            />
          </ul>
        </div>
        <div v-if="episodeComment.pageInfo.hasNextPage" class="buttonArea">
          <p>
            <button type="submit" @click="fetchMoreComments($event)">
              もっと見る
            </button>
          </p>
          <!-- /.buttonArea -->
        </div>
        <!-- /.messageArea -->
      </div>

      <div id="grid" class="gridArea">
        <h2>みんなのエピソード</h2>
        <ul class="hashRelated">
          <li v-for="(tag, index) in e.tags" :key="index">
            ＃{{ tag.name }}
          </li>
        </ul>
        <div
          v-masonry="containerId"
          class="grid"
          transition-duration="0.3s"
          item-selector=".item"
        >
          <EpisodeCard
            v-for="episode in episode.episodes"
            :key="episode.node.id"
            :episode="episode.node"
            :edit-mode="false"
          />
          <!-- /#grid -->
        </div>

        <div class="buttonArea">
          <p>
            <button
              v-if="episode.hasNextPage"
              type="submit"
              @click="getMoreRelatedEpisodes($event)"
            >
              もっと見る
            </button>
          </p>
          <!-- /.buttonArea -->
        </div>

        <!-- /.mainContents -->
      </div>
    </div>
  </div>
</template>
<script>
import { authComputed } from '@/store/helpers.js'
import { mapState } from 'vuex'
import LikeButtonP from '@/components/LikeButtonP.vue'
import SnsButtons from '@/components/SnsButtons.vue'
import EpisodeCard from '@/components/EpisodeCard.vue'
import store from '@/store/index'

export default {
  components: {
    LikeButtonP,
    SnsButtons,
    EpisodeCard
  },
  props: {
    e: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isUploading: false,
      comment: '',
      containerId: ''
    }
  },
  beforeRouteUpdate (routeTo, routeFrom, next) {
    store.dispatch('episode/fetchEpisode', routeTo.params.id).then(episode => {
      if (episode.node) {
        document.title = episode.node.title + ' | TIME CAPSULE'
        routeTo.params.e = episode.node
        this.getRelatedEpisodes(episode.node.id)
        this.fetchComments()
        window.scrollTo(0, 0)
        next()
      } else {
        next({ name: '404', params: { resource: 'episode' } })
      }
    })
  },
  computed: {
    ...authComputed,
    ...mapState(['episodeComment']),
    ...mapState(['episode']),
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.comment) {
        return true
      }

      if (this.comment.trim() === '') {
        return true
      }

      return false
    },
    pageTitle: function () {
      return this.e.title
    },
    pageUrl: function () {
      return window.location.href
    }
  },
  created: function () {
    this.fetchComments()
    this.getRelatedEpisodes(this.e.id)
  },
  beforeDestroy () {},
  beforeUpdate () {},
  mounted () {
    const url = encodeURIComponent(location.href)
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute('content', url)
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute('content', this.e.header_image)
    document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute('content', this.e.header_image)
  },
  methods: {
    saveComment (event) {
      event.preventDefault()
      this.isUploading = true
      this.$store
        .dispatch('episodeComment/postComment', {
          id: this.e.id,
          comment: this.comment
        })
        .then(() => {
          this.comment = ''
          this.isUploading = false
        })
        .catch(() => {
          this.isUploading = false
        })
    },
    fetchComments () {
      this.$store
        .dispatch('episodeComment/fetchComments', {
          id: this.e.id,
          first: 12,
          add: false
        })
        .then(() => {})
    },
    fetchMoreComments (event) {
      this.$store
        .dispatch('episodeComment/fetchComments', {
          id: this.e.id,
          first: 12,
          after: this.episodeComment.pageInfo.endCursor,
          add: true
        })
        .then(() => {})
    },
    getRelatedEpisodes (id) {
      this.$store.dispatch('episode/fetchEpisodes', {
        first: 12,
        tags: this.e.tags,
        not: id
      })
    },
    getMoreRelatedEpisodes (event) {
      event.preventDefault()
      this.$store
        .dispatch('episode/fetchEpisodes', {
          first: 12,
          after: this.episode.endCursor,
          add: true,
          tags: this.e.tags,
          not: this.e.id
        })
        .then(() => {})
    }
  }
}
</script>
<style scoped></style>
